<!--
 * @Description: 结算协议
 * @Date: 2019-12-30 14:17:08
 * @LastEditTime : 2019-12-30 17:23:58
 * @LastEditors  : 白青
 -->
<template>
  <div id='settlement-agreement'>
    <h1 class="title">结算协议</h1>
    <div class="content">
      <p>“折一”在此特别提醒用户认真阅读、充分理解本《“折一”结算协议》（下称《协议》）中各条款，包括免除或者限制折一责任的免责条款及对用户的权利限制条款，特别是法律适用、争议解决方式等条款。除非您接受本《协议》所有条款，否则您无权申请“折一”提现及其相关服务。</p>
      <p>1.结算时间:折一每个月25号进行结算上个月确认收货的佣金收入。整个结算过程一般到25号晚上才会完成，建议26号关注余额，如未完成结算的，以实际结算日为准。如用户对结算款项存在异议的，应当在3日内提出，逾期视为对结算款项无异议。</p>
      <p>2.用户可以通过绑定实名认证的支付宝账户进行提现的相应服务，用户使用折一的服务时，须同时遵守各项服务的服务条款；用户提交的支付宝账户认证姓名必须和上传的身份证图片一致，否则折一不会打款，由此带来的包括并不限于折一打款延迟、无法打款等损失由用户自行承担。</p>
      <p>3.用户已悉知并理解折一仅系注册用户通过本系统参与商业（销售）活动的收支结算工具，不涉及为注册用户代扣代缴法定税费等功能或义务。用户承诺通过折一结算取得的所有收入将依照国家税收法律相关规定自行向税务主管部门申报并缴纳。如将来无论何时，折一被税务主管部门要求代注册用户履行扣缴义务的（包括要求对在此之前折一已经结算并支付给用户的款项进行统一补缴税费的情况），则用户同意折一在用户注册账的余额中预先扣除。如注册账户余额不足导致折一先行垫付应扣缴的税费（包括但不限于税费、滞纳金、罚款等），则用户应当将在折一垫付行为发生之日起三日内全额返还垫付款，如用户未返还的，折一有权在下个月的结算款项中予以扣除。</p>
      <p>折一在此声明，您通过本软件参加的任何商业活动，与Apple Inc.无关。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SettlementAgreement',
    created () {
      document.title = '折一 - 结算协议'
    }
  }
</script>

<style lang='less' scoped>
  #settlement-agreement {
    .title {
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      margin-top: 20px;
    }
    .content {
      padding: 20px;
      p {
        text-indent: 2em;
      }
    }
  }
</style>
